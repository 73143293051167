@font-face {
  font-family: Inter-Bold;
  src: url(./assets/fonts/Inter-Bold.ttf);
}
@font-face {
  font-family: Inter-Regular;
  src: url(./assets/fonts/Inter-Regular.ttf);
}
@font-face {
  font-family: Inter-Medium;
  src: url(./assets/fonts/Inter-Medium.ttf);
}
@font-face {
  font-family: Inter-Black;
  src: url(./assets/fonts/Inter-Black.ttf);
}
@font-face {
  font-family: CookieRun-Bold;
  src: url(./assets/fonts/CookieRunBold.ttf);
}
@font-face {
  font-family: CookieRun-Regular;
  src: url(./assets/fonts/CookieRunRegular.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: Inter-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
