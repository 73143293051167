// Local config
$pageMaxWidth: 500px;

// Mobile
$designMobileWidth: 360px; // screen width used on design to scale into
$designMobileHeight: 780px; // screen height used on design to scale into

// Desktop
$designWidth: 1440px; // screen width used on design to scale into
$designHeight: 1024px; // screen height used on design to scale into

$enableScaling: true;

:export {
  pageMaxWidth: $pageMaxWidth;
  designMobileWidth: $designMobileWidth;
  designMobileHeight: $designMobileHeight;
  designWidth: $designWidth;
  designHeight: $designHeight;

  enableScaling: $enableScaling;
}
