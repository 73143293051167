@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=swap');
@import 'src/assets/styles/hangry-utils.module';

/* Notes
font-weight (
  xtra bold: 800,
  bold: 700,
  medium: 500,
  regular: 400 (normal)
)
*/

// *Heading
@mixin heading-h1-64 {
  font-family: Inter, sans-serif;
  font-weight: 800;
  font-size: scaleDesignWidthMobile(64px);
  line-height: scaleDesignWidthMobile(80px);
}
@mixin heading-h2-48 {
  font-family: Inter, sans-serif;
  font-weight: 800;
  font-size: scaleDesignWidthMobile(48px);
  line-height: scaleDesignWidthMobile(60px);
}
@mixin heading-h3-32 {
  font-family: Inter, sans-serif;
  font-weight: 800;
  font-size: scaleDesignWidthMobile(32px);
  line-height: scaleDesignWidthMobile(40px);
}
@mixin heading-h4-24 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(24px);
  line-height: scaleDesignWidthMobile(32px);
}
@mixin heading-h5-20 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(20px);
  line-height: scaleDesignWidthMobile(28px);
}
@mixin heading-h6-18 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(18px);
  line-height: scaleDesignWidthMobile(26px);
}

// *Title
@mixin title-bold-20 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(20px);
  line-height: scaleDesignWidthMobile(28px);
}
@mixin title-bold-18 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(18px);
  line-height: scaleDesignWidthMobile(26px);
}
@mixin title-bold-16 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(16px);
  line-height: scaleDesignWidthMobile(24px);
}
@mixin title-bold-14 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(14px);
  line-height: scaleDesignWidthMobile(20px);
}

// *Subtitle
@mixin subtitle-medium-20 {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: scaleDesignWidthMobile(20px);
  line-height: scaleDesignWidthMobile(28px);
}
@mixin subtitle-medium-18 {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: scaleDesignWidthMobile(18px);
  line-height: scaleDesignWidthMobile(26px);
}
@mixin subtitle-medium-16 {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: scaleDesignWidthMobile(16px);
  line-height: scaleDesignWidthMobile(24px);
}
@mixin subtitle-medium-14 {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: scaleDesignWidthMobile(14px);
  line-height: scaleDesignWidthMobile(20px);
}

// *Body
@mixin body-regular-20 {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: scaleDesignWidthMobile(20px);
  line-height: scaleDesignWidthMobile(28px);
}
@mixin body-regular-18 {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: scaleDesignWidthMobile(18px);
  line-height: scaleDesignWidthMobile(26px);
}
@mixin body-regular-16 {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: scaleDesignWidthMobile(16px);
  line-height: scaleDesignWidthMobile(24px);
}
@mixin body-regular-14 {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: scaleDesignWidthMobile(14px);
  line-height: scaleDesignWidthMobile(20px);
}

// *Caption
@mixin caption-bold-12 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(12px);
  line-height: scaleDesignWidthMobile(18px);
}
@mixin caption-medium-12 {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: scaleDesignWidthMobile(12px);
  line-height: scaleDesignWidthMobile(18px);
}
@mixin caption-regular-12 {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: scaleDesignWidthMobile(12px);
  line-height: scaleDesignWidthMobile(18px);
}

// *Footer
@mixin footer-bold-10 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(10px);
  line-height: scaleDesignWidthMobile(12px);
}
@mixin footer-medium-10 {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: scaleDesignWidthMobile(10px);
  line-height: scaleDesignWidthMobile(12px);
}

// *Label
@mixin label-bold-20 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(20px);
  line-height: scaleDesignWidthMobile(28px);
  letter-spacing: scaleDesignWidthMobile(1.25px);
  text-transform: uppercase;
}
@mixin label-bold-12 {
  font-family: Inter, sans-serif;
  font-weight: 700;
  font-size: scaleDesignWidthMobile(12px);
  line-height: scaleDesignWidthMobile(18px);
  letter-spacing: scaleDesignWidthMobile(1.25px);
  text-transform: uppercase;
}

// *Promo
@mixin promo-medium-12 {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-size: scaleDesignWidthMobile(12px);
  line-height: scaleDesignWidthMobile(18px);
  text-decoration-line: line-through;
}