$milk: #ffffff;

$black: #303030;
$blackLight: #707070;

$grey: #858c9b;
$greyLight: #bbc1ce;
$greyLighter: #f5f5f5;
$greyLightest: #fafafa;

$sauce: #bc1433;
$sauceLight: #f33055;
$sauceLighter: #ff8ea4;
$sauceLightest: #fff3f3;
$sauceDark: #a30926;
$sauceDarker: #810e23;
$sauceDarkest: #41040f;

$matcha: #12a25f;
$matchaLighter: #a0dabf;
$matchaLight: #59be8f;
$matchaLightest: #e6f7ef;
$matchaDark: #0e824c;
$matchaDarker: #0b6139;
$matchaDarkest: #074126;

$honey: #fab300;
$honeyLight: #ffc100;
$honeyLighter: #fde199;
$honeyLightest: #fff7e5;
$honeyDark: #cc9200;
$honeyDarker: #a37500;
$honeyDarkest: #644800;

$mentai: #f56200;
$mentaiLight: #ff8533;
$mentaiLighter: #ffc299;
$mentaiLightest: #fff3eb;
$mentaiDark: #cc5200;
$mentaiDarker: #994f1f;
$mentaiDarkest: #663514;

$aqua: #007af5;
$aquaLight: #3392ff;
$aquaLighter: #70b3ff;
$aquaLightest: #ebf4ff;
$aquaDark: #005fcc;
$aquaDarker: #00438f;
$aquaDarkest: #003066;

$skin: #f0d9c4;
$nose: #908376;
$clear: #f5faff;

$overlay10: rgba(0, 0, 0, 0.1);
$overlay40: rgba(0, 0, 0, 0.4);
$overlay60: rgba(0, 0, 0, 0.6);
$overlay80: rgba(0, 0, 0, 0.8);
$overlay90: rgba(0, 0, 0, 0.9);

$whiteOverlay20: rgba(255, 255, 255, 0.2);
$whiteOverlay40: rgba(255, 255, 255, 0.4);
$whiteOverlay60: rgba(255, 255, 255, 0.6);
$whiteOverlay80: rgba(255, 255, 255, 0.8);
$whiteOverlay90: rgba(255, 255, 255, 0.9);

$reddish: linear-gradient(135deg, #de2b4c 0%, #bc1433 64.05%);
$reddishLoyalty: linear-gradient(180deg, #de2b4c 0%, #bc1433 100%);

:export {
  milk: #ffffff;

  black: #303030;
  blackLight: #707070;

  grey: #858c9b;
  greyLight: #bbc1ce;
  greyLighter: #f5f5f5;
  greyLightest: #fafafa;

  sauce: #bc1433;
  sauceLight: #f33055;
  sauceLighter: #ff8ea4;
  sauceLightest: #fff3f3;
  sauceDark: #a30926;
  sauceDarker: #810e23;
  sauceDarkest: #41040f;

  matcha: #12a25f;
  matchaLighter: #a0dabf;
  matchaLight: #59be8f;
  matchaLightest: #e6f7ef;
  matchaDark: #0e824c;
  matchaDarker: #0b6139;
  matchaDarkest: #074126;

  honey: #fab300;
  honeyLight: #ffc100;
  honeyLighter: #fde199;
  honeyLightest: #fff7e5;
  honeyDark: #cc9200;
  honeyDarker: #a37500;
  honeyDarkest: #644800;

  mentai: #f56200;
  mentaiLight: #ff8533;
  mentaiLighter: #ffc299;
  mentaiLightest: #fff3eb;
  mentaiDark: #cc5200;
  mentaiDarker: #994f1f;
  mentaiDarkest: #663514;

  aqua: #007af5;
  aquaLight: #3392ff;
  aquaLighter: #70b3ff;
  aquaLightest: #ebf4ff;
  aquaDark: #005fcc;
  aquaDarker: #00438f;
  aquaDarkest: #003066;

  skin: #f0d9c4;
  nose: #908376;
  clear: #f5faff;

  overlay10: rgba(0, 0, 0, 0.1);
  overlay40: rgba(0, 0, 0, 0.4);
  overlay60: rgba(0, 0, 0, 0.6);
  overlay80: rgba(0, 0, 0, 0.8);
  overlay90: rgba(0, 0, 0, 0.9);

  reddish: linear-gradient(135deg, #de2b4c 0%, #bc1433 64.05%);
  reddishLoyalty: linear-gradient(180deg, #de2b4c 0%, #bc1433 100%);
}
